.SideDrawer {
    /* Only visible on mobile */
    position: fixed;
    width: 280px;
    max-width: 70%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 200;
    background-color: white;
    padding: 32px 16px;
    /* adjust the size to the padding */
    box-sizing: border-box;
    transition: transform 0.3s ease-out;
}

/* if display exceeds 500px */
@media (min-width: 500px){
    .SideDrawer {
        display: none;
    }
}

.Logo {
    height: 11%;
    margin-bottom: 32px;
}

.Open {
    /* shift the side drawer into the position defined above */
    transform: translateX(0);
}

.Close {
    transform: translateX(-100%);
}

