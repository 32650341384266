/**
 This is best suited for mobile phones
 */
.Burger {
    width: 100%;
    margin: auto;
    height: 250px;
    overflow: scroll;
    overflow-y: auto;
    overflow-x: auto;
    text-align: center;
    font-weight: bold;
    font-size: 1.2rem;
}
/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: gray;
    border-radius: 10px;
}
/**
 This checks to see if our screen is a proper monitor
 */
@media (min-width: 1000px) and (min-height: 700px){
    .Burger {
        width: 700px;
        height: 600px;
    }
}


/**
 This is maybe an Ipad
 */
@media (min-width: 500px) and (min-height: 401px){
    .Burger {
        width: 450px;
        height: 400px;
    }
}

/**
 This is maybe an Ipad
 */
@media (min-width: 500px) and (min-height: 400px){
    .Burger {
        width: 350px;
        height: 300px;
    }
}